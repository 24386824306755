import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';

const TransactionDetails = ({ transactionReceipt }) => {
  return (
    <section className="mt-6">
      <div>
        <div className="grid grid-cols-3 gap-4">
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Date</p>
            <p className="text-dark_blue text-14 mt-1">
              {dayjs(transactionReceipt?.created_at).format('MMM DD, YYYY')}
              <span className="text-grey font-dmsans_r">
                {' '}
                | {dayjs(transactionReceipt?.created_at).format('h:mm a')}
              </span>
            </p>
          </div>
          {!transactionReceipt?.transaction_category?.includes('purchase') && (
            <div className="font-dmsans_r w-full break-all">
              <p className="text-grey text-12">Email</p>
              <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.email}</p>
            </div>
          )}
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Description</p>
            <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.description}</p>
          </div>
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Transaction ID</p>
            <p className="text-dark_blue text-14 mt-1">
              {transactionReceipt?.transaction_reference ?? transactionReceipt?.transaction_id}
            </p>
          </div>
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey font-dmsans_r text-12">Service</p>
            <p className="text-dark_blue capitalize text-14 mt-1">
              {transactionReceipt?.transaction_category?.replaceAll('-', ' ')}
            </p>
          </div>
          {transactionReceipt?.extra_details?.senderName && (
            <div className="font-dmsans_r break-all">
              <p className="text-grey font-dmsans_r text-12">Sender details</p>
              <p className="text-dark_blue capitalize text-14 mt-1">{transactionReceipt?.extra_details?.senderName}</p>
              {transactionReceipt?.extra_details?.senderBank ? (
                <p className="text-grey text-14 mt-1">
                  {transactionReceipt?.extra_details?.senderBank} | ****
                  {transactionReceipt?.extra_details?.senderAccountNumber?.slice(4)}
                </p>
              ) : (
                <p className="text-grey text-14 mt-1">
                  {transactionReceipt?.extra_details?.sender_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.sender_tier_level}
                </p>
              )}
            </div>
          )}
          {transactionReceipt?.extra_details?.sender_tag &&
            transactionReceipt?.extra_details?.sender_name &&
            transactionReceipt?.extra_details?.sender_tier_level && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Sender details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.sender_name}
                </p>
                <p className="text-grey text-14 mt-1">
                  @{transactionReceipt?.extra_details?.sender_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.sender_tier_level}
                </p>
              </div>
            )}
          {transactionReceipt?.extra_details?.receiver_tag &&
            transactionReceipt?.extra_details?.receiver_name &&
            (transactionReceipt?.extra_details?.receiver_tier_level ||
              transactionReceipt?.extra_details?.receiver_tier_levels) && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Beneficiary details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.receiver_name}
                </p>
                <p className="text-grey text-14 mt-1">
                  @{transactionReceipt?.extra_details?.receiver_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.receiver_tier_level ??
                    transactionReceipt?.extra_details?.receiver_tier_levels}
                </p>
              </div>
            )}
          {transactionReceipt?.extra_details?.bank_name &&
            transactionReceipt?.extra_details?.receiver_account_number &&
            transactionReceipt?.extra_details?.receiver_account_name && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Beneficiary details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.receiver_account_name}
                </p>
                <p className="text-grey text-14 mt-1">
                  {transactionReceipt?.extra_details?.bank_name} | ****
                  {transactionReceipt?.extra_details?.receiver_account_number?.slice(4)}
                </p>
              </div>
            )}
          {transactionReceipt?.extra_details?.user_tag &&
            transactionReceipt?.extra_details?.user_name &&
            transactionReceipt?.extra_details?.user_tier_level && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">User details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">{transactionReceipt?.extra_details?.user_name}</p>
                <p className="text-grey text-14 mt-1">
                  @{transactionReceipt?.extra_details?.user_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.user_tier_level}
                </p>
              </div>
            )}
          {transactionReceipt?.extra_details?.beneficiary_tag &&
            (transactionReceipt?.beneficiary_name || transactionReceipt?.extra_details?.beneficiary_name) &&
            transactionReceipt?.extra_details?.beneficiary_tier_level && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Beneficiary details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.beneficiary_name ?? transactionReceipt?.extra_details?.beneficiary_name}
                </p>
                <p className="text-grey text-14 mt-1">
                  @{transactionReceipt?.extra_details?.beneficiary_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.beneficiary_tier_level}
                </p>
              </div>
            )}
          {transactionReceipt?.transaction_category === 'admin-credit-wallet' &&
            transactionReceipt?.extra_details?.sender_name &&
            transactionReceipt?.extra_details?.receiver_tag &&
            (transactionReceipt?.extra_details?.beneficiary_name || transactionReceipt?.beneficiary_name) && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Sender details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.sender_name}
                </p>
              </div>
            )}
          {transactionReceipt?.transaction_category === 'admin-credit-wallet' &&
            transactionReceipt?.extra_details?.sender_name &&
            transactionReceipt?.extra_details?.receiver_tag &&
            (transactionReceipt?.extra_details?.beneficiary_name || transactionReceipt?.beneficiary_name) && (
              <div className="font-dmsans_r break-all">
                <p className="text-grey font-dmsans_r text-12">Beneficiary details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.beneficiary_name ?? transactionReceipt?.extra_details?.beneficiary_name}
                </p>
                <p className="text-grey text-14 mt-1">
                  @{transactionReceipt?.extra_details?.receiver_tag} | Tier Level:{' '}
                  {transactionReceipt?.extra_details?.beneficiary_tier_level}
                </p>
              </div>
            )}
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Amount</p>
            {transactionReceipt?.transaction_type === 'credit' ? (
              <p className="text-[#00BB64] text-14 mt-1">
                + {formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
              </p>
            ) : (
              <p className="text-[#FF0000] text-14 mt-1">
                - {formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
              </p>
            )}
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Settlement Info</p>
            <p className="text-dark_blue text-14 mt-1 capitalize">{transactionReceipt?.service_provider}</p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Balance Before</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap">
              {formatCurrency(transactionReceipt?.wallet_balance_before)}
            </p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Balance After</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap">
              {formatCurrency(transactionReceipt?.wallet_balance_after)}
            </p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Type</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap capitalize">{transactionReceipt?.transaction_type}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransactionDetails;
TransactionDetails.propTypes = {
  transactionReceipt: PropTypes.object,
};
